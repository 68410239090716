import { Col, Container, Row } from "react-bootstrap";

export default function P3() {
  return (
    <Container>
      <Row>
        <Col className="p3-header">
          <h2>Benefits of Community Living</h2>
        </Col>
      </Row>

      <Row sm={11} md={4} className="p3-list">
        <Col sm={11} md={4} className="p3-table">
          <h3>Safe Environment</h3>
          <p>
            Residents live in a secure and structured environment that minimizes
            their chances of re-offending.
          </p>
        </Col>
        <Col sm={11} md={4} className="p3-table">
          <h3>Social Support</h3>
          <p>
            Our communal living model provides residents with social support and
            a sense of community that helps them overcome feelings of isolation
            and loneliness.
          </p>
        </Col>

        <Col sm={11} md={4} className="p3-table">
          <h3>Similar Grouping</h3>
          <p>
            We group similar individuals based on their previous convictions
            allowing. This enforces better safety precautions.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
