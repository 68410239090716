import { Col, Container, Image, Row } from "react-bootstrap";

export default function P2() {
  return (
    <Container>
      <Row>
        <Col className="p2">
          <h2>Communal Housing for Released Sex Offenders and Felons</h2>
          <Image
            src="https://images.dwell.com/photos-6063391372700811264/6133439734875033600-large/three-generations-of-a-family-in-their-home-by-gray-organschi.jpg
            "
            alt="Communal Housing" className="image-2"
          />
          <h3>Community-Based Living</h3> 
          <p>
            Our communal living model creates a sense of community and belonging that helps our residents transition back into society safely.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
