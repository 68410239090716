import { Container, Row, Col ,Image} from "react-bootstrap";
export default function Hero() {
  return (
    <>
    <div className="hero-bg">
      <Container className="hero" sm={12}>
        <Row>
          <Col className="hero"> 
          <Container>
          <Image width="150" src={"https://cdn.gamma.app/4672otpd2k1wn7e/1a73f17fda734757b0ce26728d7a6d21/original/Untitled-design-copy-2.png"} className="hero-img" fluid  />
          </Container>
            <h1 >The Second Chance Housing Group</h1>
            <p className="hero-p text-center">"Inspiring success by transforming one life at a time."</p>
            <p className="hero-p">
              Our communal housing program provides a safe and supportive
              environment for individuals who have been released from
              incarceration for committing sexual offenses and other crimes,
              giving them an opportunity to reintegrate back into society. Our
              housing is designed to group similar individuals and help
              residents meet their goals and move towards a successful future
              within certain safety parameters.
            </p>
          </Col>
        </Row>
      </Container>
      </div>
    </>
  );
}