import { Col, Container, Row } from "react-bootstrap";

export default function P5 () {
  return (
    <div className="p5">

      <Container>
          <h2>Eligibility Criteria for Communal Housing</h2>
        <Row >
          <Col sm={12} md={6} lg={6}>
            <ul>
              <li>
                <h3>Age</h3>
                <p>Residents must be 18 years or older and must have been convicted of a sexual offense.</p>
              </li>
            </ul>
            <ul>
              <li>
                <h3>Mental and Physical Health</h3>
                <p>Residents must be medically and psychologically stable enough to live in a communal environment.</p>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <ul>
              <li>
                <h3>Legal Status</h3>
                <p>Residents must meet legal criteria for community release or be participating in a court-ordered treatment program.</p>
              </li>
            </ul>
            <ul>
              <li>
                <h3>Safety Compliance</h3>
                <p>Residents must ensure no re-offending shall occur while living in our community spaces.</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>

    </div>
  )
}