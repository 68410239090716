import { Col, Container, Row } from "react-bootstrap";

export default function P8 () {
  return (
    <div className="p8">

      <Container>
        <Row>
          <Col>
            <h2>Contact Us</h2>
            <p>Contact us today to learn more about inquires and how we can help you move towards a bright future with our communal housing program. Let us provide you the resources and support necessary for individuals to successfully reintegrate back into society and prevent future offending today.</p>
            <p>Phone: <br/><a href="tel: 561-544-8888">(561)544-8888</a></p>
            <p>Email: <br/><a href="mailto: secondchancehousinggroup@gmail.com">secondchancehousinggroup@gmail.com</a></p>
          </Col>
        </Row>
      </Container>
      
    </div>

  )
}