import { Col, Container, Row, Image } from "react-bootstrap";

export default function P6 () {
  return (
    <div className="p6">

      <Container>
            <h2>Challenges of Monitoring and Supervision</h2>
            <Row sm={6} md={12} lg={12} className="p6-list">
              {/* <Col className="p6-list">
                <Image src="https://cdn.gamma.app/cdn-cgi/image/quality=80,fit=scale-down,onerror=redirect,width=500/4672otpd2k1wn7e/bbb2a80f03b14aa292d962b5199d9251/original/IMGP0175.jpg" alt="teacher-classroom"/>
                <h3>Extensive Training</h3>
                <p>Our staff receive in-depth training to ensure they are equipped to manage and monitor residents effectively.</p>
              </Col> */}
              

              <Col className="p6-list">
                <Image src="https://www.secpod.com/blog/wp-content/uploads/2017/10/Data-Breaches.jpg" alt="cyber-attack"/>
                <h3>Risk Management</h3>
                <p>We have implemented strict security measures to minimize the risk of any security breaches or incidents involving residents.</p>              
              </Col>

              <Col className="p6-list">
                <Image src="https://cdn.gamma.app/cdn-cgi/image/quality=80,fit=scale-down,onerror=redirect,width=500/4672otpd2k1wn7e/61b6abe3f2c2416698b01c8ec082e988/original/1-iZMhnn0JTc-BkvFpH5_4Sw.jpeg" alt="privacy-policy"/>
                <h3>Safety Concerns</h3>
                <p>We understand the importance of privacy and work closely with residents to ensure their rights are respected.</p>
              </Col>
            </Row>
      </Container>

    </div>
  )
}