import { Col, Container, Row, Image } from "react-bootstrap";

export default function P4() {
  return (
    <div className="p4">
      <Container className="p4-container">
        <Row className="page-4" >
          <Col className="p4-left" sm={6} md={6} lg={11}>
            <h2>How it Works: Policies and Procedures</h2>
            <ul>
              <li>
                Resident eligibility is determined through a comprehensive
                assessment process
              </li>

              <li>
                Residents must adhere to all community guidelines and rules.
              </li>

              <li>
                Any illegal activities of the resident/communal house will lead
                directly to a report to the local police.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
