import './App.scss';
import Hero from './components/Hero';
import 'bootstrap/dist/css/bootstrap.min.css';
import P2 from './components/P2';
import P3 from './components/P3';
import P4 from './components/P4';
import P5 from './components/P5';
import P6 from './components/P6';
import P7 from './components/P7';
import P8 from './components/P8';
//p3 wont readjust to column

function App() {
  return (
   <>
    <Hero/>
    <P2/>
    <P3/>
    <P4/>
    <P5/>
    <P6/>
    <div className='bottom-bg'>
      {/* <P7/> */}
      <P8/>
    </div>
   </>
  );
}

export default App;
